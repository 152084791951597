.pointsAndBadges-mainDiv {
  display: grid;
  height: 100%;
  background-color: #ffffff;
}
.pointsAndBadges-item {
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  justify-content: space-around;
}
.employee-points {
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  /* padding: 5% 8% 5% 8%; */
}
.employee-badges {
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  /* padding: 5% 8% 0% 8%; */
}
.textTitle {
  display: block;
  border: 1px solid #ffffff;
  text-align: center;
  padding: 0 10% 0 10%;
}
.text {
  color: #8e8e8e;
  font-size: 16px;
  font-family: Open Sans Regular;
}
.numbers {
  color: #303030;
  font-size: 32px;
  font-family: Open Sans Semibold;
}
