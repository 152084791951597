.CareerPageMain-div {
  display: flex;
  width: 100%;
  /* height: 200px; */
  flex-direction: column;
  background: #f8f8f8;
}
.CareerPageMain-sub-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tabs-main {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  width: 90%;
  background: #ffffff;
  padding: 5px;
  height: 58px;
}
.Upward-tab {
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 3%;
  cursor: pointer;
  border-bottom: 3px solid #ffffff;
  font-size: 16px;
  font-family: Open Sans Regular;
}
.Lateral-tab {
  cursor: pointer;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 3%;
  border-bottom: 4px solid #ffffff;
  font-size: 16px;
  font-family: Open Sans Regular;
}
