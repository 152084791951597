.closeButton-div {
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.skillgap-modal-parent > .ant-modal-content {
  height: 600px important!;
}
.closeButton {
  width: 28px;
  height: 28px;
  font-size: 28px;
  background-color: #ffffffbd;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 19px;
  margin-right: 15px;
  margin-left: auto;
  cursor: pointer;
}
.body-dialog {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  flex: 1;
}
.dailog-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: white;
  font-size: 16px;
  font-family: Open Sans Semibold;
}

@media (max-width: 599px) {
  .body-dialog {
    display: flex;
    padding: 10px 15px;
    flex-direction: column;
  }
}
.detail-description-dialog {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 3%;
}

.dialog-chart-container {
  /* margin-top: 10px; */
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875em;
}
