@font-face {
  font-family: "Open Sans Regular";
  src: url('./OpenSans-Regular.ttf')
    format("truetype");
}

@font-face {
  font-family: "Open Sans Semibold";
  src: url('./OpenSans-Semibold.ttf')
    format("truetype");
}

@font-face {
  font-family: "Open Sans Light";
  src: url('./OpenSans-Light.ttf')
    format("truetype");
}
