.timeDateContainers {
  flex: 0.35;
  display: flex;
  /* padding-top: 22px; */
  padding-bottom: 10px;
}
.startDateContainers {
  font-size: 0.875em;
  color: #828282;
  margin-left: 32px;
  display: flex;
}
.endingDateContainers {
  font-size: 0.875em;
  color: #828282;
  margin-left: 46px;
  margin-right: 18px;
  display: flex;
}
.dateTitles {
  /* margin-bottom: 12px; */
  margin-right: 12px;
  color: #303030;
}
.descriptionContainers {
  display: flex;
  flex-direction: column;
}
/* @media (max-width: 768px) {
  .timeDateContainer {
    padding-top: 0px;
    margin-bottom: 10px;
  }
  .descriptionContainer {
    display: flex;
    flex-direction: column;
  }
  .startDateContainer {
    margin-left: 32px;
    display: flex;
  }
  .endingDateContainer {
    display: flex;
  }
  .dateTitle {
    margin: 0px 10px 0px 0;
  }
} */
