.unOrderList li {
  list-style: none;
  cursor: pointer;
}

.unOrderList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.unOrderList::-webkit-scrollbar {
  width: 9px;
  background-color: #ffffff;
}

.unOrderList::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 20px;
}

.unOrderList::-webkit-scrollbar-corner {
  background-color: transparent;
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.readOnlyInput:empty:before {
  content: attr(data-placeholder);
  color: gray;
}
