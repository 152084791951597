html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0px;
}
.App {
  text-align: center;
}
