.unOrderList li {
  list-style: none;
  cursor: pointer;
}

.unOrderList::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 20px;
}

.unOrderList::-webkit-scrollbar {
  width: 3px;
  background-color: #ffffff;
}

.unOrderList::-webkit-scrollbar-thumb {
  background-color: #bbb9b9;
  border-radius: 20px;
}

.unOrderList::-webkit-scrollbar-corner {
  background-color: transparent;
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
