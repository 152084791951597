.code-list li {
  list-style: none;
  cursor: pointer;
}

.code-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.code-list::-webkit-scrollbar {
  width: 9px;
  background-color: #ffffff;
}

.code-list::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 20px;
}

.code-list::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* 
.unOrderList::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 20px;
}

.unOrderList::-webkit-scrollbar {
  width: 3px;
  background-color: #ffffff;
}

.unOrderList::-webkit-scrollbar-thumb {
  background-color: #bbb9b9;
  border-radius: 20px;
}

.unOrderList::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
