.ant-modal-content {
  min-height: 230px;
  border-radius: 13px;
}
.ant-modal-header {
  border-radius: inherit;
}

.training-modal .ant-modal-content {
  border-radius: 8px;
}
.training-modal .ant-modal-header {
  border-radius: inherit;
}

.custom-drawer .ant-drawer-body {
  padding: 0;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff808b !important;
  border-color: #ff808b !important;
}

.custom-checkbox + .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff808b !important;
}
.ant-checkbox-checked::after {
  border: 1px solid#ff808b;
  border-radius: 2px;
}

.custom-cascader {
  z-index: 6;
}

.custom-cascader-input .ant-cascader-input.ant-input {
  height: 40px;
}

.custom-cascader-input .ant-input:focus {
  border-color: #ff808b !important;
  box-shadow: 0 0 0 2px #ffa7ae86 !important;
}

.custom-cascader-input .ant-input:hover {
  border-color: #ff808b !important;
}

.custom-cascader-input.ant-cascader-picker:focus .ant-cascader-input {
  border: 1px solid #ff808b !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px #ffa7ae86;
}
.custom-cascader-input .ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #ff808b;
  border-right-width: 1px !important;
}
