.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  background: #00000000;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #ff808b;
  cursor: pointer;
  border-radius: 100%;
  border: 3px solid white;
  box-shadow: 0 1px 3px 0px #64b5fa;
}
