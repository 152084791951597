.camera {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background-color: #ffffff;
  padding: 8px 8px 8px 8px;
  position: absolute;
  left: 76px;
  bottom: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.name {
  color: #303030;
  font-family: Open Sans Regular;
  font-size: 24px;
}
.role {
  color: #8e8e8e;
  font-size: 18px;
  font-family: Open Sans Regular;
  margin-top: 5px;
}
