.skilss-mainDiv1 {
  display: flex;
  height: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.skillsCard1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
  padding: 1.5% 0 5% 3%;
  border-radius: 5px;
}
.skillsCard-header1 {
  flex: 0.3;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.skillsHeadingLine1 {
  width: 76%;
  margin-left: 10px;
  border: 1px solid #f6f6f7;
}
.skillsHeading1 {
  padding-left: 20px;
  padding-right: 10px;
  color: #303030;
  font-size: 16px;
  font-family: Open Sans SemiBold;
}
.skills-group1 {
  padding-left: 11%;
  padding-right: 10%;
  padding-bottom: 2%;
}
.Skills-contents1 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
}
.skillsName1 {
  height: 32px;
  width: 106px;
  border: 1px solid #4d4cac;
  border-radius: 20px;
  padding: 2% 0% 2% 3%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: Open Sans Regular;
  font-size: 12px;
  color: #4d4cac;
}
.skillsName1 {
  height: 32px;
  width: 106px;
  border: 1px solid #4d4cac;
  border-radius: 20px;
  padding: 2% 0% 2% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-family: Open Sans Regular;
  font-size: 12px;
  color: #4d4cac;
}
.circle1 {
  border-radius: 50%;
  border: 1px solid #f1f1f8;
  background-color: #f1f1f8;
  width: 16px;
  height: 16px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.group-heading1 {
  color: #303030;
  font-family: Open Sans SemiBold, Regular;
  font-size: 14px;
  margin-bottom: 2%;
}
.skill1 {
  width: 62%;
}
.circlepart1 {
  width: 10%;
  cursor: pointer;
}
